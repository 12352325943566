*{
	box-sizing: border-box;
	&::before, &::after{
		box-sizing: border-box;
	}
}
html, body {
	position: relative;
	display: block;
	min-height: 100%;
	font-family: $baseFont;
	color: $fontBaseColor;
}

#site {
	@include display(flex);
	@include flex-direction(column);
	@include align-items(stretch);
	min-height: 100vh;
	background: #eee;
	.site-content {
		@include flex(1);
		position: relative;
		width: 1260px;
		margin: 0 auto;
	}
}
.content{
	padding: 30px 0;
	background: white; 
	&--green{
		background: $baseColor;
		color: white;
	}
	&--gray{
		background: $secondaryColor;
	}
}
.img-responsive{
	max-width: 100%;
	height: auto;
}
.shadow{
	box-shadow: 0 30px 50px rgba(0,0,0,0.2);
}
h1,h2,h3,h4{
	text-transform: uppercase;
	position: relative;
	&.border-green{
		padding: 0 0 0 20px;
		margin-bottom: 30px;
		&:before{
			width: 4px;
			height: 100%;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			position: absolute;
			content: "";
			display: block;
			background: $baseColor; 
		}		
	}
	&.border-white{
		padding: 0 0 0 20px;
		color: white;
		margin-bottom: 40px;
		&:before{
			width: 4px;
			height: 100%;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			position: absolute;
			content: "";
			display: block;
			background: white; 
		}
	}
}
p{
	font-size: 18px;
	line-height: 1.5;
	margin: 0 0 20px;
	text-align: justify;
	a{
		color: $baseColor;
		font-weight: 700;
		text-decoration: none;
	}
}
ul{
	font-size: 18px;
	line-height: 1.5;
	margin: 0 0 20px;
	text-align: justify;
	list-style: none;
	padding: 0;
	li{
		position: relative;
		padding-left: 15px;
		&:before{
			content: '-';
			font-size: 18px;
			position: absolute;
			left: 0;
		}
	}
}
blockquote{
	background: #f7f7f7;
	color: $fontBaseColor;
	padding: 30px;
	font-size: 24px;
	font-style: italic;
	text-align: center;
	display: block;
	width: 100%;
	margin: 30px 0;
}
.row{
	&--flex{
		@include display(flex);
		@include align-items(center);
	}
}
.map{
	margin-top: 40px;
}
.img-tools{
	max-width: 100%;
	height: auto;
	float: right;
}
.signature{
	max-width: 200px !important;
	margin: -30px 20px 0 0;
	float: right;
	fill: $fontBaseColor;
}
.mt0{
	margin-top: 0 !important;
}
.mb60{
	margin-bottom: 60px !important;
}
.pn{
	padding: 0;
}
.posr{
	position: relative;
}
.posa{
	position: absolute;
}

@include media-query-max(1260px){
	#site {
		.site-content {
			width: 100%;
		}
	}
}
@include media-query-max($tablet){
	.content{
		padding: 50px 0;
	}
	.row{
		&--flex{
			display: block;
		}
	}
	.img-tools{
		float: none;
		max-width: 80%;
		display: block;
		margin: 50px auto 0;
	}
}