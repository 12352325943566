.slider-container{
	padding: 0;
	position: relative;
}
.swiper{
	&-container {
	    width: 100%;
	    height: 340px;
	    margin: 0;
	    // padding-right: 30%;
	}
	&-slide {
	    position: relative;
    	transition: opacity 0.3s;
	    h2{
	    	display: none;
	    }
	    &.swiper-slide-next{
	    	opacity: 0.6;
	    }
	}
	&__img-handler{
		background-size: cover;
		background-position: 0 0;
		width: 100%;
		height: 100%;
		display: block;
		transition: all 0.5s;
		&.color{
			position: relative;
		}
		&.gray{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 100%;
		}
	}
	&__cover{
		background: $baseColor;
		width: 570px;
		height: 110px;
		z-index: 5;
		position: absolute;
		left: 0;
		top: 200px;
		h2{
	    	position: absolute;
	    	opacity: 1;
	    	margin: 0;
	    	top: 50%;
	    	left: 50px;
	    	color: white;
	    	font-size: 26px;
	    	font-weight: 400;
	    	text-transform: none;
	    	width: 590px;
	    	transform: translateY(-50%);
	    	strong{
	    		font-weight: 700;
	    	}
	    }
	}
	&__dots{
		width: 20px;
		height: 110px;
		display: block;
		position: absolute;
		top: -210px;
		right: 20px;
		margin: auto;
		z-index: 10;
		&-container{
			position: relative;
			z-index: 1;
		}
		.swiper-pagination-bullet{
			width: 17px;
			height: 17px;
			border: 2px solid $baseColor;
			display: block;
			transition: all 0.5s;
			border-radius: 50%;
			background: none;
			margin-bottom: 15px;
			opacity: 1;
			&.swiper-pagination-bullet-active{
				background: $baseColor;
			}
			&:hover{
				cursor: pointer
			}
		}
	}
}
.no-opacity{
	opacity: 0;
}

@include media-query-max($siteWidth){
	.swiper{
		&-container {
			padding: 0;
		}
		&__cover{
			h2{
				width: 100%;
				padding: 0 20px;
				font-size: 24px;
				left: 0;
			}
		}
	}
}

@include media-query-max($xtablet){
	.swiper{
		&__cover{
			width: 100%;
			height: 110px;
			top: 220px;
			h2{
				font-size: 20px;
				top: 30px;
				@include transform(none);
			}
		}
		&__dots{
			top: -270px;
		}
	}
}