.section-video {
  display: block;
  position: relative;
  padding: 25px 0;
  padding-bottom: 40%;
  text-align: center;
  iframe {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 600px;
    height: 100% !important;
    margin: auto;
  }
}
