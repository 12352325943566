.certificate{
	float: left;
	width: 100%;
	padding: 35px 0;
	border-bottom: 1px solid #e8e8e8;
	@include display(flex);
	@include align-items(center);
	@include justify-content(space-between);
	&:last-child{
		border: 0;
		padding-bottom: 0;
	}
	&__name{
		text-align: left;
		padding-bottom: 0;
	}
	&-badge-container{
		text-align: center;
		float: none;
	}
	&--badge{
		float: none;
		display: inline-block;
		margin: 0 25px 20px;
		vertical-align: middle;
	}
}

@include media-query-max($xtablet){
	.certificate{
  		@include flex-direction(column);
		@include justify-content(center);
		&-badge-container{
			text-align: center;
			float: left;
			width: 100%;
			padding-top: 50px;
		}
		&__name{
			text-align: center;
			display: block;
			padding-bottom: 20px;
		}
	}
}