.download {
  display: table;
  width: 100%;
  padding: 20px 0 5px 0;
  h1, h2, h3, h4, h5 {
    margin-bottom: 15px;
  }
  .layout-col--3 &, .layout-col--4 & {
    display: block;
    .file {
      &__format {
        display: none;
      }
      &__description {
        padding: 10px;
        border: 1px $baseColor solid;
      }
    }
  }
  .file {
    &__format {
      display: table-cell;
      vertical-align: top;
      padding: 18px 15px 10px 5px;
      font-size: 10px !important;
      font-weight: 500;
      text-transform: uppercase;
      background: url(../images/icons/download.png) top left no-repeat;
    }
    &__description {
      display: table-cell;
      vertical-align: top;
      padding-left: 10px;
      border-left: 1px #cccccc solid;
    }
    &__title {
      margin-bottom: 3px;
      font-size: 14px;
      line-height: 1.1;
    }
    &__link {
      display: block;
      font-size: 14px;
      font-weight: 600;
    }
    &__size {
      padding-left: 10px;
      font-size: 12px;
      color: #bbbbbb;
    }
  }
}

.download-boxes{
  @include display(flex);
  @include flex-wrap(wrap);
  margin: -1.5%;
  padding: 30px 0;
  &__box{
    position: relative;
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
    width: 22%;
    margin: 1.5%;
    padding: 60px 20px 120px 20px;
    text-align: center;
    background: $secondaryColor;
      
    *{
      text-align: center;
    }
  }
  .db-box{
    &__name{
      display: inline-block;
      width: 100%;
      p{
        text-align: center;
        font-size: 24px;
      }
    }
  }
  a.btn{
    position: absolute;
    bottom: 60px;
    left: 20px;
    right: 20px;
    margin: auto;

  }
}