.contact{
	&__form{
		width: 100%;
	  	@include display(flex);
	  	@include align-items(stretch);		
	}
	&__col{
		&--1{
			width: 30%;
		}
		&--2{
			width: 70%;
			padding-left: 40px;
		}
	}
	&__field{
		display: block;
		margin-bottom: 40px;
	}
	&__label{
		display: block;
		font-weight: 700;
		margin-bottom: 5px;
	}
	&__input{
		display: block;
		width: 100%;
		height: 40px;
		padding: 0 10px;
		border: 1px solid rgba(0,0,0,0.1);
		transition: all 0.5s;
		&:focus{
			border: 1px solid rgba(0,0,0,0.3);
		}
		&.error{
			border-color: #c44444;
		}
		&--textarea{
			padding: 10px;
			resize: none;
			height: 355px;
		}
	}
	&__message{
		font-size: 18px;
		font-style: italic;
		&--success{
			color: #49aa2b;
		}
		&--error{
			color: #c44444;
		}
	}
}
@include media-query-max($mobile){
	.contact{
		&__form{
			display: block;
			margin-top: 50px;
		}
		&__col--1, &__col--2{
			width: 100%;
			display: block;
			padding: 0;
		}
		&__input{
			&--textarea{
				height: 250px;
			}
		}		
	}
}