@import "config";

@import "partials/layout";
@import "partials/header";
@import "partials/footer";

@import "widgets/pagination";
@import "widgets/button";

@import "sections/slider";
@import "sections/certificates";
@import "sections/icons";
@import "sections/images";
@import "sections/contact-form";
@import "sections/download";
@import "sections/cookies";
