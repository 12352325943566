.icons{
	&__container{
		display: block;
		font-size: 0;
		margin: 0 -10px;
		text-align: center;
		&--margin{
			margin-top: 50px;
		}
	}
	&__img{
		padding: 0 0 30px;
		fill: white;
		max-width: 100px;
		max-height: 100px;
		position: relative;
		top: 0;
		transition: all 0.2s;
		&--row{
			max-width: 140px;
			max-height: 140px;
		}
	}
	&__name{
		font-size: 16px;
		font-weight: 300;
		display: block;
		color: $fontBaseColor;
		text-decoration: none;
		vertical-align: bottom;
	}
	&__icon{
		display: inline-block;
		padding: 0 20px 55px;
		text-decoration: none;
		vertical-align: top;
		transition: all 0.5s;
		&:hover, &.current{
			.icons__img{
				top: -3px;
			}
		}
		&--light{
			padding: 0 40px 20px;			
			.icons__img{
				opacity: 0.2;
				.cls-2, .cls-1{
					fill: $fontBaseColor;	
					transition: all 0.5s;	
				}
			}
			.icons__name{
				color: rgba(0,0,0,0.2)
			}
			&:hover, &.current{
				.icons__img{
					opacity: 1;
					.cls-2{
						fill: $baseColor;
					}
					.cls-1{
						fill: $fontBaseColor;
					}
					fill: $baseColor;
				}
				.icons__name{
					color: $fontBaseColor;
				}
			}
		}
		&--vert{
			padding-bottom: 30px;
			.icons__img{				
				max-width: 80px;
				max-height: 80px;
				padding-bottom: 15px;
				&--zywnosc{
					margin-left: 13px;
				}
				&--monitoring{
					margin-right: 6px;
				}
				&--higiena{
					margin-left: 13px;
				}
				&--przechowalnicze{
					margin-right: 8px;
				}
				&--konsulting{
					margin-left: 5px;
				}
			}
		}
	}
}


@include media-query-max($tablet){
	.icons{
		&__container{
			margin-top: 50px;
		}
	}
}

@include media-query-max($mobile){
	.icons{
		&__icon{
			display: block;
			&:last-child{
				padding-bottom: 0;
			}
		}
	}
}