.btn {
	display: inline-block;
	padding: 10px 15px;
	font-size: 14px;
	font-weight: 700;
	color: white;
	background: $baseColor;
	border-radius: 6px;
	border: 2px solid rgba(0,0,0,0);
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 1px;
	@include transition(all 200ms linear);
	&:hover {
		color: $baseColor;
		border: 2px solid rgba(149,199,36,1);
		background: transparent;
	}
	&--white{
		background: white;
		border: 2px solid white;
		color: $fontBaseColor;
		&:hover{
			border-color: white;
			background: $baseColor;
			color: white;
		}
	}
	&--margin{
		margin: 20px 0;
	}
}
