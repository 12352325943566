.images{
	&-container{
		display: block;
		font-size: 0;
		margin: 30px -20px;
		text-align: center;
	}
	&__img{
		display: inline-block;
		max-width: 360px;
		margin: 30px 20px 0;
		box-shadow: 0 5px 10px rgba(0,0,0,0.2);
	}
}
.floating-image{
	&--left{
		float: left;
		margin: 20px 20px 20px 0;
	}
	&--right{
		float: right;
		margin: 20px 0 20px 20px;
		max-width: 50%;
	}
	&--center{
		display: block;
		max-width: 100%;
		margin: 20px auto;
	}
	&--nm{
		margin-top: 0;
		margin-bottom: 10px;
	}
	&--mb{
		margin-top: 0;
		margin-bottom: 30px;
	}
	&--mt{
		margin-top: 35px;
	}
	&--mtb{
		margin-top: 35px;
		margin-bottom: 35px;
	}
}

@include media-query-max($xtablet){
	.images{
		&__img{
			max-width: 280px;
		}
	}
}