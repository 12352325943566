// .dl{
// 	&__box{
// 		background: $secondaryColor;
// 		width: 100%;
// 		margin: 20px 0;
// 		min-height: 395px;
// 		text-align: center;
// 		@include display(flex);		
// 		@include align-items(center);
// 		@include flex-direction(column);
// 		@include justify-content(center);
// 	}
// 	&__name{
// 		text-align: center;
// 		font-size: 24px;
// 		width: 100%;
// 		padding: 0 20px 20px;
// 		display: inline-block;
// 	}
// }
// @include media-query-max($xtablet){
// 	.dl{
// 		&__box{
// 			min-height: 200px;
// 			padding: 20px 0;
// 		}
// 		&__name{
// 			font-size: 18px;
// 			padding: 0 10px 10px;
// 		}
// 	}
// }