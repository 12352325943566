.gallery {
  display: block;
  padding: 0 !important;
  padding-bottom: 100px !important;
  &--products {
    background: #aaaaaa !important;
  }
  &__cols {
    @include clearfix;
  }
  .site-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .cms & {
    margin: 60px 0;
    padding-bottom: 0 !important;
  }
  .column {
    float: left;
    padding: 0 10px;
    img {
      margin-bottom: 20px;
      width: 100%;
    }
  }
  &__images {
    @include clearfix;
  }
  .item {
    position: relative;
    a {
      display: block;
      &:hover {
        .item__info {
          opacity: 1;
        }
      }
    }
    &__info {
      @include display(flex);
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      text-transform: uppercase;
      color: white;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      z-index: 999;
      @include transition(opacity 200ms linear);
      div {
        align-self: center;
      }
    }
  }
}

.gallery--hover .item__info {
  opacity: 1 !important;
  &:hover {
    opacity: 0.6 !important;
  }
}

.gallery-list {
  &__images {
    display: block;
    width: auto;
    margin: 0 -20px;
    @include clearfix;
    .item {
      display: block;
      width: 200px;
      margin-bottom: 40px;
      padding: 0 20px;
      img {
        display: block;
        width: 100%;
      }
      p {
        display: block;
        width: 100%;
        padding: 10px 15px;
        color: black;
        background: white;
        @include transition(all 200ms linear);
      }
      &:hover {
        p {
          color: white;
          background: $baseColor;
        }
      }
    }
    .column {
      .item {
        width: 100%;
      }
    }
  }
}
