.site-footer {
	display: block;
	width: 1260px;
	margin: 0 auto;
	&__upper{
		background: $baseColor;
		display: block;
		color: white;
		padding: 55px 0;
		h1{
			margin: 0 0 50px;
		}
		p{
			font-size: 16px;
			text-align: left;
			a{
				color: white;
			}
		}
		strong{
			font-size: 18px;
		}
		ul{
			list-style: none;
			padding: 0;
			margin: 0;
			li{
				margin-bottom: 5px;
				padding: 0;
				text-align: left;
				&:before{
					display: none;
				}
				a{
					color: white;
					text-decoration: none;
					font-size: 16px;
				}
			}
		}
		.phone{
			font-size: 24px;
			strong{
				font-size: 28px;
			}
		}
	}
	&__lower{
		background: white;
		color: $fontBaseColor;
		padding: 20px 0;
		.copy{
			float: left;
		}
		.made-by{
			float: right;
		}
		a{
			text-decoration: none;
			font-weight: 700;
			color: $fontBaseColor;
		}
	}
	&__logo{
		display: block;
		margin: 48px 0 0 0;
		width: 70%;
		&-img{
			max-width: 100%;
			max-height: 132px;
			.cls-1, .cls-2{
				fill: white;
			}
		}
	}
}

@include media-query-max(1260px){
	.site-footer{
		width: 100%;
	}
}

@include media-query-max($xtablet){
	.site-footer{
		&__upper{
			padding: 50px 0;
			ul{
				padding-bottom: 30px;
			}
		}
		&__lower{
			.copy, .made-by{
				float: none;
				display: block;
				text-align: center;
				margin-bottom: 15px;
			}
		}
		&__logo{
			margin: 0 0 20px;
		}
	}
}