.site-header {
	display: block;
	background: white;
	padding: 15px 0 10px;
	position: relative;
	width: 1260px;
	margin: 0 auto;
	z-index: 10;
	&__logo{
		&-img{
			max-width: 110px;
			max-height: 61px;
		}
	}
	nav{
		display: inline-block;
		padding: 10px 0 0;
		/* padding: 20px 0; */
		float: right;
	}
	&__nav{
		list-style: none;
		float: right;
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: right;
		&-item{
			display: inline-block;
			padding: 0 20px 15px;
			position: relative;
			&:before{
				display: none;
			}
			&:last-child{
				padding: 0 0 0 20px;
			}
			&--submenu{
				padding-right: 35px;
				&::after{
					position: absolute;
					content: '\25BC';
					right: 20px;
					top: 8px;
					width: 10px;
					height: 10px;
					font-size: 8px;
				}
				&:hover{
					.site-header__nav-submenu{
						display: block;
					}
				}
			}
			.arrow{
				display: none;
			}
		}
		&-link{
			text-decoration: none;
			color: $fontBaseColor;
			font-size: 16px;
			&:hover{
				color: $baseColor;
			}
			&.current{
				color: $baseColor;
				font-weight: 700;
			}
		}
		&-submenu{
			display: none;
			position: absolute;
			top: 35px;
			left: 0;
			text-align: left;
			background: $baseColor;
			padding: 15px;
			border-radius: 10px;
			margin: 0;
			width: auto;
			z-index: 10;
			li{
				list-style: none;
				margin: 10px 0;
				padding: 0;
				&:before{
					display: none;
				}
				&:first-child{
					margin: 0 0 10px;
				}
				&:last-child{
					margin: 10px 0 0;
				}
				a{
					color: white;
					white-space: nowrap;
					text-decoration: none;
				}
			}
		}
	}
	&__hamburger{
		display: none;
	}
}

@include media-query-max(1260px){
	.site-header{
		width: 100%;
	}
}
@include media-query-max($siteWidth){
	.site-header{
		&__nav{
			&-item{
				padding: 0 10px 15px;
				&--submenu{
					&:after{
						right: 0;
					}
				}
				&:last-child{
					padding: 0 0 0 10px;
				}
			}
		}
	}
}

@include media-query-max($netbook){
	.site-header{
		nav{
			display: inline-block;
			padding: 20px 0 0;
			float: right;
			width: 100%;
		}
		&__nav{
			float: none;
			display: block;
			text-align: center;
		}
		&__logo{
			display: block;
			width: 133px;
			margin: auto;
		}
	}
}

@include media-query-max($xtablet){
	.site-header{
		padding-bottom: 0;
		&__logo{
			float: left;
			display: inline-block;
		}
		&__nav{
			max-height: 0;
			overflow: hidden;
			background: white;
			position: absolute;
			left: 0;
			width: 100%;
			transition: all 0.5s linear;
			z-index: 20;
			&.menu-open{
				padding-bottom: 30px;
				max-height: 600px;
			}
			&-item{
				display: block;
				text-align: center;
				padding: 0 20px 25px;
				&--submenu{
					&::after{
						display: none;
					}
					.arrow{
						display: inline-block;
						font-size: 14px;
						position: absolute;
						right: 20px;
						width: 50px;
						height: 30px;
						cursor: pointer;
					}
					&:hover{
						.site-header__nav-submenu{
							display: none;
						}
					}
				}
			}
			&-submenu{
				position: relative;
				top: 0;
				li{
					margin: 15px 0;
					a{
						white-space: initial;
					}
				}
			}
		}
		&__hamburger{
			display: inline-block;
			padding: 10px;
			background: $baseColor;
			width: 50px;
			height: 50px;
			border-radius: 5px;
			position: relative;
			float: right;
			cursor: pointer;
			margin-top: 7px;
			span{
				width: 30px;
				height: 4px;
				display: block;
				background: white;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				transition: background 0.5s;
				&:before, &:after{
					width: 100%;
					height: 4px;
					background: white;
					display: block;
					position: absolute;
					content: "";
					left: 0;
					transition: all 0.5s;
				}
				&:before{
					top: -10px;
				}
				&:after{
					bottom: -10px;
				}
				&.open{
					background: $baseColor;
					&:before{
						transform: rotate(-45deg);
    					transform-origin: right bottom;
    					top: -11px;
    					left: -2px;
					}
					&:after{
						left: -5px;
						transform: rotate(45deg);
    					transform-origin: right bottom;
					}
				}
			}
		}
	}
}
