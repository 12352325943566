.site-cookies{
	position: fixed;
	bottom: 0;
	background: $secondaryColor;
	width: 100%;
	display: table-row;
	p{
		font-size: 14px;
	}
	.site-wrapper{
		display: table;
		max-width: $siteWidth;
		margin: 0 auto;
		padding-top: 20px;
	}
	&__col{
		display: table-cell;
		&:nth-child(1){
			width: 90%;
		}
		&:nth-child(2){
			width: 10%;
			text-align: right;
		}
	}
	.cookies-close{
		display: inline-block;
		background: $baseColor;
		color: white;
		padding: 5px 10px;
		border: 0;
		border-radius: 5px;
	}
}