.pagination {
  position: relative;
  display: block;
  text-align: center;
  li {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    &::before {
      display: none;
    }
    &:first-child, &:last-child {
      a {
        font-size: 22px;
        line-height: 1;
      }
    }
    a {
      display: block;
      padding: 5px 8px;
      font-weight: 700;
      color: black;
      background: transparent;
      border-radius: 6px;
      &:hover {
        color: $baseColor;
      }
    }
    &.active a, &.current a {
      color: white;
      background: $baseColor;
    }
  }
}
